import {
  BrowserRouter,
  Routes,
  Route,
  useRoutes,
  useNavigate,
} from "react-router-dom";
import { LoginPage } from "../Pages/Login/Login";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SideNavBar } from "../Components/SideNavBar/SideNavBar";
import { Header } from "../Components/common/header/header";
import { useEffect } from "react";
import { NotFound } from "../Pages/NotFound/NotFound";
import { RoutesWrapper } from "./RoutesWrapper";
import { UsersList } from "../Pages/UsersList/UsersList";
import { NewUser } from "../Pages/NewUser/NewUser";
import { ViewDevelopers } from "../Pages/ViewDevelopers/ViewDevelopers";
import { DevReport } from "../Pages/DevReport/DevReport";
import { UsersEdit } from "../Pages/UsersEdit/UsersEdit";
import { AvailabilityEdit } from "../Pages/AvailabilityEdit/AvailabilityEdit";
import { PersonalDetails } from "../Pages/ViewUserDetails/PersonalDetails/PersonalDetails";
import { ProfessionalDetails } from "../Pages/ViewUserDetails/ProfessionalDetails/ProfessionalDetails2";
import ResetPasswordPage from "../Pages/ResetPassword/ResetPassword";
import { ProgressEdit } from "../Pages/ProgressEdit/ProgressEdit";
import { Referrals } from "../Pages/Referrals/Referrals";
import { Questions } from "../Pages/Questions/Questions";
import { Interview } from "../Pages/Interview/Interview";
import { CandidateDetails } from "../Pages/CandidateDetails/CandidateDetails";
import { AssignHR } from "../Pages/AssignHR/AssignHR";
import { HRDetails } from "../Pages/HRDetails/HRDetails";
import { ViewRecordings } from "../Pages/ViewRecordings/ViewRecordings";
import Rubric from "../Pages/Rubric/Rubric";
import { AssignExpert } from "../Pages/AssignExpert/AssignExpert";

export const AllRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<LoginPage />}></Route>
        <Route
          exact
          path="/reset-password"
          element={<ResetPasswordPage />}
        ></Route>
        <Route exact path="/*" element={<SubRoutes />}></Route>
      </Routes>
      <ToastContainer />
    </BrowserRouter>
  );
};

const SubRoutes = () => {
  const renderSubRoutes = useRoutes([
    { path: "/view-users", element: <UsersList /> },
    { path: "/edit-users", element: <UsersEdit /> },
    { path: "/view-developers", element: <ViewDevelopers /> },
    { path: "/edit-availability", element: <AvailabilityEdit /> },
    { path: "/edit-progress", element: <ProgressEdit /> },
    { path: "/create-user", element: <NewUser /> },
    { path: "/dev-report", element: <DevReport /> },
    { path: "/details/personal", element: <PersonalDetails /> },
    { path: "/details/professional", element: <ProfessionalDetails /> },
    { path: "/referrals", element: <Referrals /> },
    { path: "/questions", element: <Questions /> },
    { path: "/interview", element: <Interview /> },
    { path: "/candidate-details", element: <CandidateDetails /> },
    { path: "/assign-hr", element: <AssignHR /> },
    { path: "/assign-expert", element: <AssignExpert /> },
    { path: "/hr-details", element: <HRDetails /> },
    { path: "/dev-recording/*", element: <ViewRecordings /> },
    { path: "/rubric", element: <Rubric /> },
    { path: "/*", element: <NotFound /> },
  ]);

  return (
    <>
      {sessionStorage.getItem("loginSuccess") ? (
        <>
          <SideNavBar />
          <Header />
          <RoutesWrapper>{renderSubRoutes}</RoutesWrapper>
        </>
      ) : (
        <NavigateToLogin />
      )}
    </>
  );
};

const NavigateToLogin = () => {
  const navigate = useNavigate();

  useEffect(() => {
    console.log("navigateToLogin=>");
    navigate("/");
  }, []);
  return <></>;
};
