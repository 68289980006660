export const formatDateTime = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "short" });
  const year = date.getFullYear();
  const hours = date.getHours();
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedTime = hours % 12 || 12;

  return `${day} ${month} ${year}, ${formattedTime} ${ampm}`;
};

export const formatTimeSlots = (slots) => {
  return slots
    .map((slot) => {
      const start = slot.startTime ? formatTimeforHR(slot.startTime) : "-";
      const end = slot.endTime ? formatTimeforHR(slot.endTime) : "-";
      return `${start} - ${end}`;
    })
    .join(", ");
};

const formatTimeforHR = (time) => {
  // Remove leading zeros from the hour part
  const [timePart, period] = time.split(" ");
  const [hour, minute] = timePart.split(":");
  const formattedHour = parseInt(hour, 10).toString();
  return `${formattedHour}${minute ? `:${minute}` : ""} ${period}`;
};

export const formatTime = (time) => {
  // Remove leading zeros from the hour part
  const [timePart, period] = time.split(" ");
  const [hour, minute] = timePart.split(":");
  const formattedHour = parseInt(hour, 10).toString();
  return `${formattedHour}${minute ? `:${minute}` : ""} ${period}`;
};

export const formatTimeTo12Hour = (time) => {
  let [hours, minutes] = time.split(":");
  hours = parseInt(hours, 10);
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12; // Convert '0' to '12' for 12 AM
  hours = hours < 10 ? "0" + hours : hours; // Pad single digit hours with leading zero if needed
  return `${hours}:${minutes} ${ampm}`;
};

//Date to time without periods in 24 hours (O/P : 03:30, 14:15)
export const formatTimeWithoutPeriod = (date) => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  if (hours < 10) hours = "0" + hours;
  if (minutes < 10) minutes = "0" + minutes;
  const strTime = hours + ":" + minutes;
  return strTime;
};

//Date to date without time (O/P : 18 July 2024)
export const formatDate = (inputDate) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const dateParts = inputDate.split("-");
  const year = dateParts[0];
  const month = months[parseInt(dateParts[1], 10) - 1];
  const day = parseInt(dateParts[2], 10);

  return `${day} ${month} ${year}`;
};
