import axios from "axios";
import { toast } from "react-toastify";
import { CANDIDATE_PROFILE } from "./URLS";

export const candidateProfile = async (data) => {
    try {
        const endPoint = CANDIDATE_PROFILE;
        const Config = {
            // headers: {
            //     Authorization: `Bearer ${sessionStorage.getItem('token')}` // Add the authorization token to the request headers
            // }
        };
        return await axios.post(endPoint, data, Config).then(res => res?.data)
    } catch (err) {
        console.log("error in candidateProfile==>", err)
        toast.error(err?.response?.data?.exception)
    }
}